export const environment = {
  production: false,
  staging: true,
  sentryEnvironment: 'staging',
  backendUrl: 'https://api.stage.member.ukcreditratings.com',
  checkoutUrl: 'https://checkout.ukcreditratings.com',

  // This is set during the build process, using replace-in-file package.  See pipelines for usage.
  release: 'portal@2384',

  mixpanelKey: '',

  // ThreeDS
  paayFormId: 'deferred-auth-card-form',
  paayApiKey: 'XiCxEybADdgxz7cHPtSEAB9o7cgj8z9Y'
};
