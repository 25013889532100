import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataPartnerService {
  public static isPartner(afid: number): boolean {
    return [488367, 561717, 514270, 514271, 515415, 466801, 562231, 562605].indexOf(afid) > -1;
  }
}
