import {Preferences} from '../../models/preferences';
import {AccountSettings} from '../../models/account-settings.interface';
import {IAccount} from '../../models/account';
import {UpdateBillingInfoRequest} from '../../models/account/update-billing-info-request';
import {UserStatus} from 'src/app/shared/models/user-status';
import {ProductInterface} from 'src/app/shared/models/product.interface';

export namespace SessionActions {

  export class UpdateAccount {
    static readonly type = '[Session] Update Account';
    constructor(public account: IAccount) {}
  }

  export class UpdateAccountNullable {
    static readonly type = '[Session] Update Account Nullable';
    constructor(public account: IAccount) {}
  }

  export class UpdateAuthenticated {
    static readonly type = '[Session] Update Authenticated';
    constructor(public state: boolean) {}
  }

  export class UpdateProducts {
    static readonly type = '[Session] Update Products';
    constructor(public products: ProductInterface[]) {}
  }

  export class UpdatePreferences {
    static readonly type = '[Session] Update Preferences';

    constructor(public preferences: Preferences) {
    }
  }

  export class UpdateAccountStatus {
    static readonly type = '[Session] Update Account Status';

    constructor(public status: UserStatus) {
    }
  }

  export class UpdateAccountSettings {
    static readonly type = '[Session] Update Account Settings';

    constructor(public accountSettings: AccountSettings) {
    }
  }

  export class UpdateBillingInfo {
    static readonly type = '[Session] Billing Info';

    constructor(public billInfo: UpdateBillingInfoRequest) {
    }
  }

  export class ClearState {
    static readonly type = 'ClearState';

    constructor() {
    }
  }
}
