import {RegisterResult} from 'src/app/shared/models/register-result';
import {KbaOutcome} from 'src/app/shared/models/kba-outcome';

/**
 * Result from calling Account\RegisterController
 */
export interface RegisterResultInterface {
  result: RegisterResult;
  guid: string;
  reason: string;
  kba_questions: KbaQuestionnaire | null;

  // This isn't part of the response from the agency, but from the RegisterController adding more information.
  message?: string;
}

export interface AnswerKbaResultInterface {
  outcome: KbaOutcome;
  agency_guid: string;
  kba_questions: KbaQuestionnaire | null;

  // This isn't part of the response from the agency, but from the RegisterController adding more information.
  message?: string;
}

export interface KbaAnswerOption {
  Id: string;
  Text: string;
  /**
   * Only available for staging environments
   */
  isCorrect?: boolean;
}

export interface Answers {
  KbaAnswerOption: KbaAnswerOption[];
  internArray?: any;
  internArrayIsArray?: any;
  internArrayOffset?: any;
}

export interface Question {
  Answers: Answers;
  Id: string;
  Text: string;
}

export interface QuestionGroup {
  Text: string;
  Questions: Question[];
}

export class KbaQuestionnaire {
  id: number;
  groups: QuestionGroup[];
  extras: object[];

  constructor(source: Partial<KbaQuestionnaire> = null) {
    Object.assign(this, source);
  }

  numberOfQuestions(): number {
    return this.groups.reduce((qCount: number, group: QuestionGroup) => {
      return qCount + group.Questions.length;
    }, 0);
  }

  getEmptyAnswerObject(): object {
    return this.groups.reduce((answers: object, group: QuestionGroup) => {
        group.Questions.forEach(question => {
          answers[question.Id] = '';
        });
        return answers;
      }, {});
  }
}
